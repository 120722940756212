import React, { useCallback, useEffect, useRef } from "react"
import mergeRefs from "react-merge-refs";
import styled from "styled-components"
import { useRanger } from "react-ranger"

const Container = styled.div`
  position: relative;
  height: 1rem;
  user-select: none;
`

const StyledTrack = styled.div`
  position: relative;
  top: 25%;
  bottom: 0;
  height: 50%;
  border-radius: 999px;
  overflow: hidden;
`

const createZonesFromNumber = (zoneLength, min, max) => {
  let zones = [];

  const part = (max - min) / zoneLength;

  for(let i = min; i < max; i += part ) {
    zones.push(i);
  }

  return zones;
}

const Track = React.forwardRef(({ min, max, zones, children }, ref) => {
  const usedZones = Number.isInteger(zones) ? createZonesFromNumber(zones, min, max) : zones;
  
  const maskRects = usedZones.reduce((acc, curr, index) => {
    if(index === 0) return acc;
    const leftPerc = (curr / (max - min)) * 100
    return [
      ...acc,
      <rect x={`${leftPerc}%`} y="0" height="100%" width="2px" fill="#000" key={index} />,
    ]
  }, [])

  return (
    <StyledTrack ref={ref}>
      <svg width="100%" height="100%">
        <defs>
          <linearGradient
            x1="1.46748311%"
            y1="60.4329427%"
            x2="100%"
            y2="60.4329427%"
            id="gradient"
          >
            <stop stopColor="#9B7C7C" offset="0%"></stop>
            <stop stopColor="#54DE4A" offset="100%"></stop>
          </linearGradient>
          <mask id="mask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            {maskRects}
          </mask>
        </defs>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#gradient)"
          mask="url(#mask)"
        />
      </svg>
      {children}
    </StyledTrack>
  )
});

const Handle = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  background: #FFFFFF;
  border: 0.75rem solid #93C447;
  cursor: pointer;
  box-shadow: 2px 2px 8px -1px rgba(0,0,0,0.50);
`;

export const Ticks = styled.div`
  position: relative;
  width: 100%;
`

export const Tick = styled("div")``;

export const TickLabel = styled("div")`
  position: absolute;
  font-size: 0.6rem;
  transform: translate(-50%, 1em);
  white-space: nowrap;
`;

const TickRenderer = ({ value, getTickProps }) => (
  <Tick {...getTickProps()}>
    <TickLabel>{value}</TickLabel>
  </Tick>
);

export const Slider = ({ min, max, value, zones, ticks: ticksProp, renderTick = TickRenderer, className, handleRef, onChange }) => {
  const handleChange = useCallback(([value]) => {
    onChange && onChange(value)
  }, [onChange]);

  const { getTrackProps, ticks, handles } = useRanger({
    min,
    max,
    ticks: ticksProp,
    stepSize: 1,
    values: [value],
    onDrag: handleChange,
  })
  
  return (
    <Container className={className}>
      <Track {...getTrackProps()} zones={zones} min={min} max={max} />
      {ticksProp && (
        <Ticks>
          {ticks.map((props, key) => <React.Fragment key={key}>{renderTick(props)}</React.Fragment>)}
        </Ticks>
      )}
      {handles.map(({ getHandleProps }) => {
        const handleProps = getHandleProps();
        return (
          <Handle
            ref={handleRef}
            {...handleProps}
          />
        )
      })}
    </Container>
  )
}
