import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Img from "gatsby-image"
import { selfAlignClasses, textAlignClasses } from "../util/cssClasses"
import { useIntl } from "../util/useIntl"

import { Heading, HeadingTitle, HeadingSubtitle } from "../components/Heading"

const ImageWrapper = ({ image, children }) => {
  if (image && image.localFile) {
    return (
      <div className="flex flex-row w-full max-w-screen-md">
        <div className="flex flex-col flex-1">{children}</div>
        <Img
          alt={image.localFile.alternativeText}
          fluid={image.localFile.childImageSharp.fluid}
          className="hidden md:block md:w-full"
        />
      </div>
    )
  }

  return children
}
const strapiSizeToLevel = size => {
  switch (size) {
    case "display1":
      return "display-1"
    case "display2":
      return "display-2"
    case "display3":
      return "display-3"
    default:
      return size
  }
}
export const HeadlineRenderer = ({ data, className, defaultSize, defaultElement }) => {
  const { intl } = useIntl();
  
  const cleanData = data || {};

  return (
    cleanData.title &&
    cleanData.subtitle && (
      <ImageWrapper image={cleanData.logo}>
        <Heading
          level={strapiSizeToLevel(cleanData.size || defaultSize)}
          as={cleanData.element || defaultElement}
          className={cx(
            "w-full",
            selfAlignClasses(cleanData, "left"),
            textAlignClasses(cleanData, "left"),
            className
          )}
        >
          {cleanData.subtitle && (
            <HeadingSubtitle>{intl(cleanData?.subtitle).get()}</HeadingSubtitle>
          )}
          {cleanData.title && <HeadingTitle>{intl(cleanData?.title).get()}</HeadingTitle>}
        </Heading>
      </ImageWrapper>
    )
  ) || null;
}

const sizes = [
  "display1",
  "display2",
  "display3",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];

const elements = ["h1", "h2", "h3", "h4", "h5", "h6", "span"];

HeadlineRenderer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.shape({
      en: PropTypes.string,
      nl: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      en: PropTypes.string,
      nl: PropTypes.string,
    }),
    align: PropTypes.oneOf(["left", "center", "right"]),
    alignText: PropTypes.oneOf(["left", "center", "right"]),
    size: PropTypes.oneOf(sizes),
    element: PropTypes.oneOf(elements),
  }),
  defaultSize: PropTypes.oneOf(sizes),
  defaultElement: PropTypes.oneOf(elements)
}

HeadlineRenderer.defaultProps = {
  defaultSize: "h2",
  defaultElement: undefined,
  align: "left",
  data: {},
}

export default HeadlineRenderer
