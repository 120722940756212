import React from "react"
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { Button } from "../components/Button"
import { ArrowLink } from "../components/ArrowLink"
import {
  Testimonial,
  TestimonialStory,
  TestimonialAvatar,
  TestimonialCompanyPicture,
  TestimonialAuthor,
  TestimonialCompany,
} from "../components/Testimonial"
import HeadlineRenderer from "./HeadlineRenderer"
import { StrapiImageRenderer } from "../strapi/StrapiImageRenderer"
import { useIntl } from "../util/useIntl"
import { marginClasses } from "../util/cssClasses";

export const TestimonialsSectionRenderer = ({ data }) => {
  const allTestimonials = useStaticQuery(graphql`
    query TestimonialsSectionQuery {
      strapi {
        testimonials {
          ...Testimonial
        }
      }
    }
  `)

  const { intl } = useIntl()

  const testimonials = data.testimonials.map(({ testimonial: { id } }) => {
    const testimonial = allTestimonials.strapi.testimonials.find(
      ({ id: cId }) => {
        return id === cId
      }
    )
    return (
      <Testimonial className="lg:rounded-lg p-m">
        <TestimonialStory>
          <ReactMarkdown>{intl(testimonial.story).get()}</ReactMarkdown>
        </TestimonialStory>
        <TestimonialAvatar>
          <StrapiImageRenderer data={testimonial.avatar} />
        </TestimonialAvatar>
        <TestimonialCompanyPicture>
          <StrapiImageRenderer data={testimonial.companyImage} />
        </TestimonialCompanyPicture>
        <ArrowLink href={intl(testimonial.link?.href).get()}>
          {intl(data.testimonialsLinkText).orPlaceholder()}
        </ArrowLink>
        <TestimonialAuthor>{testimonial.personName}</TestimonialAuthor>
        <TestimonialCompany>{testimonial.personCompany}</TestimonialCompany>
      </Testimonial>
    )
  })

  return (
    <section className={cx("flex flex-col items-center", marginClasses(data.margins, { marginBottom: "xl" }))}>
      <HeadlineRenderer
        data={data.headline}
        defaultSize="h2"
        className="text-center mb-m"
      />
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-xl gap-y-l">
        {testimonials}
        {data.buttonText.text && (
          <div className="flex justify-end items-end xl:col-span-full">
            <Button
              href={intl(data.buttonLink?.href).get()}
              type="primary-outline"
              size="l"
            >
              {intl(data.buttonText).orPlaceholder()}
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}
