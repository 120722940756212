import { useStaticQuery, graphql } from "gatsby";

export const usePricingData = () => {
  const data = useStaticQuery(graphql`query PricingQuery {
    strapi {
      subscriptionPricing {
        pricingTable
      }
    }
  }`);

  return data.strapi?.subscriptionPricing?.pricingTable.sort(([,,price]) => price) || [];
}