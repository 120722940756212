import React, { useContext } from "react";
import AnimateHeight from 'react-animate-height';
import cx from "classnames";

import { CollapsibleContext } from "./CollapsibleContext";

export const CollapsibleContent = ({ children, className, ...rest }) => {
  const { collapsed } = useContext(CollapsibleContext);
  return (
    <AnimateHeight duration={150} height={collapsed ? 0 : 'auto'} easing="ease-in-out" className={cx("w-full", "overflow-hidden", className )} {...rest}>
      { children }
    </AnimateHeight>
  )
}