import React, { useContext } from "react";
import styled, { css } from "styled-components";
import cx from "classnames";
import { MdExpandMore, MdExpandLess } from "react-icons/md"

import { CollapsibleContext } from "./CollapsibleContext";

const iconStyles = css`
  display: inline-block;
  font-size: 1.5rem;
`

const ExpandIcon = styled(MdExpandMore)`${iconStyles}`;
const CollapseIcon = styled(MdExpandLess)`${iconStyles}`;

export const CollapsibleButton = ({ children, className, ...rest }) => {
  const { collapsed, setCollapsed } = useContext(CollapsibleContext);
  return (
    <div role="button" className={cx("flex justify-between w-full cursor-pointer", className)} onClick={() => setCollapsed(!collapsed)} {...rest}>
      {children} {collapsed ? <ExpandIcon /> : <CollapseIcon />}
    </div>
  )
};
