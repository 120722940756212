import React, { useEffect, useRef } from "react"
import cx from "classnames"
import { marginClasses } from "../util/cssClasses"
import Helmet from "react-helmet"

function decodeHTMLEntities(str) {
  if (str && typeof str === "string" && document) {
    var element = document.createElement("div")
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "")
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "")
    element.innerHTML = str
    str = element.textContent
    element.textContent = ""
    return str
  }
}

export default function waitFor(something, timeout = 10) {
  return new Promise((resolve, reject) => {
    let waited = 0

    function wait(interval) {
      setTimeout(() => {
        waited += interval
        // some logic to check if script is loaded
        // usually it something global in window object
        if (something()) resolve()
        if (waited >= timeout * 1000) {
          return reject({ message: "Timeout" })
        }
        wait(interval * 2)
      }, interval)
    }

    wait(30)
  })
}

export const CalendlySectionRenderer = ({ data }) => {
  const ref = useRef()

  useEffect(() => {
    if (!window) return
    waitFor(() => !!window.Calendly).then(() => {
      // eslint-disable-next-line no-undef
      Calendly.initInlineWidget({
        url: decodeHTMLEntities(data.url.text),
        parentElement: ref.current,
      })
    })
  }, [data.url.text])

  return (
    <>
      <Helmet>
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://assets.calendly.com/assets/external/widget.css"
        />
      </Helmet>
      <div
        id="calendlyWidget"
        ref={ref}
        className={cx("calendly-inline-widget", marginClasses(data.margins))}
        style={{ minWidth: 320, height: 700 }}
        data-auto-load="false"
      ></div>
    </>
  )
}
