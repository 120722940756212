import React, { useEffect, useCallback, useState } from "react"
import cx from "classnames"
import isMobile from "is-mobile"
import { marginClasses } from "../util/cssClasses"
import Img from "gatsby-image"
import { Heading, HeadingTitle } from "../components/Heading"
import { Button } from "../components/Button"
import { MarkdownText } from "../components/MarkdownText"
import { useIntl } from "../util/useIntl"

export function JoinSectionRenderer({ data }) {
  const [pincode, setPincode] = useState(undefined)
  const { intl } = useIntl()

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      window.location.assign(`${data.redirectTo.text}/?code=${pincode}`)
    },
    [pincode, data.redirectTo]
  )

  useEffect(() => {
    isMobile({ tablet: true, featureDetect: true }) &&
      window.location.assign(data.redirectTo.text)
  }, [data.redirectTo])

  const handlePincodeChange = useCallback(e => {
    setPincode(e.target.value)
  }, [])

  return (
    <section
      className={cx(
        "flex flex-row justify-between w-full",
        marginClasses(data.margins, { marginBottom: "xl", marginTop: "l" })
      )}
    >
      <div>
        <div className="flex flex-col max-w-prose justify-center">
          <MarkdownText>
            {intl(data.message).orPlaceholder("--empty--")}
          </MarkdownText>
        </div>
      </div>
      {data.image?.localFile?.childImageSharp?.fixed && (
        <Img
          alt={data.image.localFile.alternativeText}
          fixed={data.image.localFile.childImageSharp.fixed}
        />
      )}
      <div className="flex flex-col justify-center">
        <form
          className="flex flex-col p-l rounded-xl bg-gray-100 max-w-prose"
          onSubmit={handleSubmit}
        >
          <Heading>
            <HeadingTitle level="h4">
              {intl(data.pincodeTitle).orPlaceholder("Continue on desktop")}
            </HeadingTitle>
          </Heading>
          <p className="mt-s">
            {intl(data.pincodeMessage).orPlaceholder("Enter your session code")}
          </p>
          <input
            type="text"
            className="rounded-xl bg-gray-200 my-m px-s text-mono font-bold text-heading-xs leading-10 text-center"
            placeholder="_ _ _ _"
            size={4}
            maxlength={4}
            value={pincode}
            onChange={handlePincodeChange}
          />
          <Button type="secondary" size="l">
            {intl(data.pincodeButtonText).orPlaceholder("Join session")}
          </Button>
        </form>
      </div>
    </section>
  )
}
