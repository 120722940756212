import React from "react";
import styled from "styled-components"
import cx from "classnames";
import { Button } from "../Button"

const StyledButton = styled(Button).attrs({
  type: "naked",
  size: "s",
})``

export const NavigationMenuAnchor = ({ children, href, className, ...rest }) => {
  return (
    <StyledButton {...rest} href={href} className={cx(href && "hover:underline", !href && "cursor-default", className)}>
      {children}
    </StyledButton>
  )
}
