import React, { useContext, useState } from "react"
import cx from "classnames"
import { marginClasses, maxWClasses } from "../util/cssClasses"
import { StoryContentRenderer } from "./StorySectionRenderer"
import { PageContext } from "../contexts/PageContext"

import {
  Collapsible,
  CollapsibleButton,
  CollapsibleContent,
} from "../components/Collapsible"

import { Heading, HeadingTitle, HeadingSubtitle } from "../components/Heading"

const DesktopFaqSection = ({ data }) => {
  const { data: pageData } = useContext(PageContext)

  const categories = data?.categories.map(({ category }) => {
    return pageData.strapi.faqCategories.find(
      ({ id: cId }) => cId === category.id
    )
  })

  const [activeCategory, setActiveCategory] = useState(0)
  const items = categories[activeCategory]?.items || []

  const [open, setOpen] = useState(items[0]?.id)

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-col space-y-m lg:space-y-0 lg:flex-row lg:flex-1">
        {categories.length > 1 && (
          <div className="flex flex-row w-full items-start space-x-s lg:space-x-0 lg:space-y-s lg:flex-none lg:flex-col lg:w-1/4 lg:mr-l p-m rounded-3xl shadow-xl bg-gray-100">
            {categories.map((category, index) => {
              return (
                <button
                  className={cx(
                    "hover:underline",
                    "hover:cursor-pointer",
                    "text-lg",
                    index === activeCategory && ["font-bold", "underline"]
                  )}
                  onClick={() => setActiveCategory(index)}
                >
                  {category.name.text}
                </button>
              )
            })}
          </div>
        )}
        <div className="flex flex-col flex-1 h-full rounded-3xl shadow-xl bg-gray-100 lg:-ml-m p-m">
          <Heading level="h2" className="text-left">
            <HeadingSubtitle>{data.headline?.subtitle.text}</HeadingSubtitle>
            <HeadingTitle>{data.headline?.title.text}</HeadingTitle>
          </Heading>
          {items.map(
            item =>
              item.faq_item && (
                <Collapsible
                  key={item.faq_item.id}
                  open={item.faq_item.id === open}
                  className={cx(
                    "w-full-m-s",
                    item.faq_item.id === open ? "divide-y" : "border-t-2",
                    "first:border-0"
                  )}
                >
                  <CollapsibleButton
                    onClick={() =>
                      setOpen(open =>
                        open === item.faq_item.id ? undefined : item.faq_item.id
                      )
                    }
                    className={cx("font-semibold", "py-s")}
                  >
                    - {item.faq_item.title.text}
                  </CollapsibleButton>
                  <CollapsibleContent>
                    <div className="mt-s">
                      <StoryContentRenderer data={item.faq_item.content} />
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              )
          )}
        </div>
      </div>
    </div>
  )
}

export const FAQSectionRenderer = ({ data }) => {
  return (
    <section
      className={cx(
        "w-full flex flex-1",
        marginClasses(data.margins, { marginBottom: "xl" }),
        maxWClasses(data.faqMaxWidth)
      )}
    >
      <DesktopFaqSection data={data} />
    </section>
  )
}
