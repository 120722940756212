import React from "react"
import cx from "classnames"
import { Button } from "../Button"
import styled from "styled-components"

const fontClassName = props => {
  switch (props.level) {
    case 4:
      return "text-sm font-light"
    case 3:
      return "text-sm"
    case 2:
      return "font-light"
    default:
      return "font-regular"
  }
}

const IconWrapper = styled.span.attrs({
  className: "rounded-full overflow-hidden",
})`
  display: inline-block;
  vertical-align: middle;
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.5em;
  img > {
    width: 100%;
    height: 100%;
  }
`

export const NavigationLink = props => {
  const { icon, children, className, ...rest } = props
  return (
    <Button
      {...rest}
      className={cx("hover:underline", "block", "no-wrap", fontClassName(props), className)}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </Button>
  )
}
