import React from "react";
import cx from "classnames";
import Puff from "../../images/Puff.inline.svg";

const Loading = ({ className }) => {
  return (
    <div className={cx("flex", "w-full", "h-full", "items-center", "justify-center", "bg-gray-100", className)}>
      <Puff className="w-10 h-10" />
    </div>
  )
}

export default Loading;