import React, { useContext } from "react";
import { PopoutContext } from "./PopoutContext";
import cx from "classnames";
import styled from "styled-components";


const StyledSvg = styled.svg`
  width: ${props => props.placement === "top" || props.placement === "bottom" ? "0.5em" : "0.25em" };
  height: ${props => props.placement === "left" || props.placement === "right" ? "0.5em" : "0.25em" };
`;

const polygons = {
  "top": "0 0, 100 0, 50 100",
  "right": "100 0, 0 50, 100 100",
  "bottom": "0 100, 50 0, 100 100",
  "left": "0 0, 100 50, 0 100"
}

const Polygon = ({ placement, style, className }) => {
  return (
    <polygon points={polygons[placement]} style={style} className={className} />
  );
};

const placementClass = {
  top: "bottom-0",
  bottom: "top-0",
  left: "right-0",
  right: "left-0"
}

export const PopoutArrow = ({ style, className }) => {
  const { arrowRef, placement } = useContext(PopoutContext);

  const viewBox = placement === "left" || placement === "right" ? "0 0 100 100" : "0 0 100 100";


  return (
    <div style={style} className={cx(placementClass[placement], className)} ref={arrowRef}>
      <StyledSvg width="100%" height="100%" placement={placement} viewBox={viewBox} preserveAspectRatio="none">
        <Polygon placement={placement} style={{ fill: "currentColor" }} />
      </StyledSvg>
    </div>
  );
}