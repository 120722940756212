import { useContext } from "react";
import { PageContext } from "../contexts/PageContext"

export const useIntl = () => {
  const { language, defaultLanguage } = useContext(PageContext);

  return {
    intl: (data) => {
      const resolved = data ? data[language || defaultLanguage] : undefined;
      return {
        get: () => resolved,
        or: (alternativeValue) => resolved || alternativeValue, 
        orPlaceholder: (placeholder = "--empty--") => resolved || placeholder,
      }
    },
  }
}