import React from "react"
import ReactMarkdown from "react-markdown"
import cx from "classnames"
import Img from "gatsby-image"
import HeadlineRenderer from "./HeadlineRenderer"
import { useIntl } from "../util/useIntl"
import {
  selfAlignClasses,
  textAlignClasses,
  marginClasses,
} from "../util/cssClasses"

export const CompaniesSectionRenderer = ({ data }) => {
  const { intl } = useIntl()
  const links = data.links.map(link => {
    let img
    if (link.image.localFile.childImageSharp) {
      img = (
        <Img
          className="m-auto h-full w-full"
          fluid={link.image.localFile.childImageSharp.fluid}
          alt={link.altText.text}
          imgStyle={{
            objectFit: "contain",
          }}
          objectPosition="50% 50%"
        />
      )
    } else {
      img = (
        <img
          className="w-full"
          style={{ maxWidth: "150px", objectFit: "contain" }}
          src={link.image.localFile.publicURL}
          alt={link.altText.text}
        />
      )
    }
    return (
      <div className="relative aspect-w-16 aspect-h-9" key={link.id}>
        <a
          className="transform absolute hover:shadow-lg w-full h-full flex justify-center rounded-md transition duration-500 ease-in-out hover:scale-125"
          href={intl(link.location.href).get()}
        >
          {img}
        </a>
      </div>
    )
  })

  return (
    <section
      className={cx(
        "flex flex-col w-full",
        marginClasses(data.margins, { marginBottom: "xl" })
      )}
    >
      <HeadlineRenderer
        data={data.headline}
        defaultSize="h2"
        className="mb-m max-w-screen-md w-full"
      />
      <ReactMarkdown
        className={cx(
          "w-full",
          "max-w-screen-md",
          selfAlignClasses(data.text, "left"),
          textAlignClasses(data.text, "left")
        )}
      >
        {intl(data.text).orPlaceholder()}
      </ReactMarkdown>
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-x-m gap-y-l mt-l md:mt-xl">
        {links}
      </div>
    </section>
  )
}
