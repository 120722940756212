import React from "react";
import { TestimonialQuotes } from "./TestimonialQuotes";
import styled from "styled-components";
import { theme } from 'twin.macro'

export const Grid = styled.div.attrs({
  className: "grid gap-x-m"
})`
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "avatar avatar"
    "quotes quotes"
    "story story"
    "link link"
    "author companypicture"
    "company companypicture";

  @media (min-width: ${theme('screens.md')}) { 
    grid-template-columns: 1fr 150px;
    grid-template-rows: auto auto;
    grid-template-areas: 
      "quotes avatar"
      "story avatar"
      "story companypicture"
      "link link"
      "author author"
      "company company"
  }
`;

export const Testimonial = ({ children, ...props }) => {
  return (
    <div {...props}>
      <Grid>
        <TestimonialQuotes />        
        {children}
      </Grid>
    </div>
  )
}

