import React from "react"
import cx from "classnames"
import { useStrapiNavigationAction } from "./useStrapiNavigationAction"
import { NavigationLink } from "../components/Navigation"

export const StrapiNavigationAction = ({ id, className, ...rest }) => {
  const action = useStrapiNavigationAction(id) || {}

  const type =
    action.style && action.color ? `${action.color}-${action.style}` : null

  const icon = action.icon?.localFile?.publicURL && (
    <img src={action.icon?.localFile?.publicURL} alt={action.label?.text} />
  )

  return (
    <NavigationLink
      size={!type ? "s" : "m"}
      className={cx(type, className)}
      type={type}
      icon={icon}
      {...rest}
      {...action.props}
    />
  )
}
