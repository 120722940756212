import React from "react"

import { VideoSectionRenderer } from "./VideoSectionRenderer"
import { TestimonialsSectionRenderer } from "./TestimonialsSectionRenderer"
import { CompaniesSectionRenderer } from "./CompaniesSectionRenderer"
import { ModulesSectionRenderer } from "./ModulesSectionRenderer"
import { StorySectionRenderer } from "./StorySectionRenderer"
import { HeroSectionRenderer } from "./HeroSectionRenderer"
import { JoinSectionRenderer } from "./JoinSectionRenderer"
import { PricingSectionRenderer } from "./PricingSectionRenderer"
import { PricingDetailSectionRenderer } from "./PricingDetailSectionRenderer"
import { TextSectionRenderer } from "./TextSectionRenderer"
import { FAQSectionRenderer } from "./FAQSectionRenderer"
import { ImageCarouselSectionRenderer } from "./ImageCarouselSectionRenderer"
import { CalendlySectionRenderer } from "./CalendlySectionRenderer"
import { ColumnsSectionRenderer } from "./ColumnsSectionRenderer"
import { ShowcaseSectionRenderer } from "./ShowcaseSectionRenderer"

const sectionMap = {
  STRAPI_ComponentSectionsVideoSection: VideoSectionRenderer,
  STRAPI_ComponentSectionsTestimonialsSection: TestimonialsSectionRenderer,
  STRAPI_ComponentSectionsCompaniesSection: CompaniesSectionRenderer,
  STRAPI_ComponentSectionsModulesSection: ModulesSectionRenderer,
  STRAPI_ComponentSectionsStorySection: StorySectionRenderer,
  STRAPI_ComponentSectionsHeroSection: HeroSectionRenderer,
  STRAPI_ComponentSectionsJoinSection: JoinSectionRenderer,
  STRAPI_ComponentSectionsPricingSection: PricingSectionRenderer,
  STRAPI_ComponentSectionsPricingDetailSection: PricingDetailSectionRenderer,
  STRAPI_ComponentSectionsTextSection: TextSectionRenderer,
  STRAPI_ComponentSectionsFaqSection: FAQSectionRenderer,
  STRAPI_ComponentSectionsImageCarouselSection: ImageCarouselSectionRenderer,
  STRAPI_ComponentSectionsCalendlySection: CalendlySectionRenderer,
  STRAPI_ComponentSectionsColumnsSection: ColumnsSectionRenderer,
  STRAPI_ComponentSectionsShowcaseSection: ShowcaseSectionRenderer,
}

export const SectionRenderer = ({ data: { __typename, ...sectionData } }) => {
  const RenderedSection = sectionMap[__typename] || (() => null)
  return <RenderedSection data={sectionData} />
}
