import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div.attrs({
  className: "w-full flex justify-center"
})`
  grid-area: avatar;
`;

export const ImageContainer = styled.div.attrs({
  className: "rounded-full overflow-hidden shadow-lg"
})`
  width: 150px;
  height: 150px;
`;

export const TestimonialAvatar = ({ children }) => {
  return (
    <Wrapper>
      <ImageContainer>
        {children}
      </ImageContainer>
    </Wrapper>
  )
}