import React from "react"
import cx from "classnames"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import HeadlineRenderer from "./HeadlineRenderer"
import { marginClasses } from "../util/cssClasses"

const Card = styled.div.attrs({
  className: "flex flex-col rounded-lg",
})``

const CardContent = styled.div.attrs({
  className: "p-m",
})``

export const VideoSectionRenderer = ({ data }) => {
  const features = data.features.map(f => (
    <Card key={f.id}>
      <div className="flex justify-center align-center pt-l text-blue-500">
        <img
          src={f.icon.localFile.publicURL}
          alt={"feature"}
          className={"w-xl"}
        />
      </div>
      <CardContent>
        <h3 className="heading-6 font-bold pb-s text-center">{f.title.text}</h3>
        <p className="text-center">
          <ReactMarkdown>{f.description.text}</ReactMarkdown>
        </p>
      </CardContent>
    </Card>
  ))
  return (
    <section
      className={cx(
        "flex flex-col items-center",
        marginClasses(data.margins, { marginBottom: "xl" })
      )}
    >
      <HeadlineRenderer
        data={data.headline}
        defaultSize="h2"
        className="text-center mb-l"
      />
      <div className="w-full max-w-3xl w-full">
        <div className="aspect-w-16 aspect-h-9 w-full bg-gray-700 rounded-lg overflow-hidden shadow-2xl">
          <iframe
            title={"QANDR Introduction"}
            src={`https://www.youtube.com/embed/${data?.video?.videoId?.text}?autoplay=0&loop=0&mute=0&cc_load_policy=0&cc_lang_pref=nl&modest_branding=1&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 w-full content-center gap-x-m md:gap-x-l gap-y-m mt-l md:mt-xl">
        {features}
      </div>
    </section>
  )
}
