import { useEffect, useState, useMemo } from "react"

export const useBreakpoint = (spec, defaultBreakpoint) => {
  const [breakpoint, setBreakpoint] = useState(defaultBreakpoint)

  const pairs = useMemo(() => {
    return Object.keys(spec).reduce((acc, c) => {
      return [...acc, [c, spec[c]]]
    }, [])
  }, [spec])

  useEffect(() => {
    const calculateAndSetBreakpoint = () => {
      const [breakpoint] = pairs.reduce((acc, curr) => {
        if (acc.length === 0 || (curr[1] > acc[1] && curr[1] < window.innerWidth)) {
          return curr;
        }
        return acc;
      }, []);
      setBreakpoint(breakpoint);
    };

    calculateAndSetBreakpoint()
    const resizeListener = () => {
      calculateAndSetBreakpoint();
    }

    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [pairs])

  return breakpoint;
}
