import React from "react";
import styled from "styled-components";

const Position = styled.div.attrs({
  className: "flex items-start justify-center mt-m"
})`
  grid-area: companypicture;
`;

export const TestimonialCompanyPicture = ({ children }) => {
  return (
    <Position>
      {children}
    </Position>
  )
}