import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Heading, HeadingTitle } from "./Heading";

const renderers = {
  heading: ({ level, children }) => {
    return (
      <Heading level={`h${level}`}>
        <HeadingTitle children={children} />
      </Heading>
    )
  },
  link: ({ node, children }) => {
    return <a className="text-blue-500 hover:underline" href={node.url}>{children}</a>
  },
  paragraph: ({ node, children }) => {
    return <p className={"py-s"} children={children} />
  },
  blockquote: ({ children }) => {
    return (
      <blockquote
        className="my-s px-l py-s italic border-l-4"
        children={children}
      ></blockquote>
    )
  },
}

export const MarkdownText = styled(ReactMarkdown).attrs({
  renderers,
})``;