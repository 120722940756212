import React from "react"
import cx from "classnames"
import { StrapiRichTextRenderer } from "../strapi/StrapiRichTextRenderer"
import { StrapiTextRenderer } from "../strapi/StrapiTextRenderer"
import { StrapiImageRenderer } from "../strapi/StrapiImageRenderer"
import { StrapiYoutubeVideoRenderer } from "../strapi/StrapiYoutubeVideoRenderer"
import { marginClasses, maxWClasses } from "../util/cssClasses"
import HeadlineRenderer from "./HeadlineRenderer"
import { ImageCarousel } from "../components/ImageCarousel"
import * as constants from "../strapi/strapiComponentConstants"

const RichTextRenderer = ({ data }) => {
  return (
    <StrapiRichTextRenderer className={cx("w-full", "text-body")} data={data} />
  )
}

const TextRenderer = ({ data }) => {
  return (
    <StrapiTextRenderer data={data} className={cx("w-full", "text-body")} />
  )
}

const ImageRenderer = ({ data }) => {
  return (
    <StrapiImageRenderer
      data={{
        ...data.image,
        // The alias needs to mapped back, very ugly, but GraphQL won't work otherwise.
        align: data.storyImageAlign,
      }}
      className={cx("mb-m", "w-full")}
      style={{ zIndex: -1 }}
    />
  )
}

const ImageCarouselRenderer = ({ data }) => {
  const images = data.images?.map(({ image: { resolved } }) => {
    return {
      src: resolved.localFile.publicURL,
      alt: resolved.localFile.alternativeText,
    }
  })

  return (
    <div className={cx("w-full", "relative", "my-m")}>
      <div className={cx("aspect-w-16", "aspect-h-9")}>
        <ImageCarousel className={cx("w-full", "h-full")} images={images} />
      </div>
    </div>
  )
}

const YouTubeVideoRenderer = ({ data }) => {
  return (
    <div className="max-w-2xl w-full mb-l">
      <div className="aspect-w-16 aspect-h-9 w-full">
        <StrapiYoutubeVideoRenderer data={data} />
      </div>
    </div>
  )
}

export const StoryContentRenderer = ({ data }) => {
  return (
    <>
      {data.map(c => {
        if (!c || !c.__typename) return null
        const key = `${c.__typename}--${c.id}`
        switch (c.__typename) {
          case constants.PAGE_CONTENT_RICH_TEXT:
            return <RichTextRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_TEXT:
            return <TextRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_IMAGE:
            return <ImageRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_YOUTUBE_VIDEO:
            return <YouTubeVideoRenderer data={c} key={key} />
          case constants.PAGE_CONTENT_IMAGE_CAROUSEL:
            return <ImageCarouselRenderer data={c} key={key} />
          default:
            return null
        }
      })}
    </>
  )
}

StoryContentRenderer.defaultProps = {
  content: [],
}

const alignContentClasses = ({ alignContent = "center" }) => {
  switch (alignContent) {
    case "left":
      return "items-start"
    case "center":
      return "items-center"
    case "right":
      return "items-end"
    default:
      return "items-start"
  }
}

export function StorySectionRenderer({ data }) {
  if (data.story) {
    return (
      <section
        className={cx(
          "flex",
          "flex-col",
          "max-w-screen-md",
          marginClasses(data.margins, { marginBottom: "xl" }),
          maxWClasses(data.maxWidth, "md"),
          alignContentClasses(data.story)
        )}
      >
        <HeadlineRenderer defaultSize="display2" data={data.story.headline} />
        <StoryContentRenderer data={data.story.content} />
      </section>
    )
  }
  return null
}
