import React from "react"

export const StrapiYoutubeVideoRenderer = ({ data = {}, ...rest }) => {
  return data.videoId?.text ? (
    <iframe
      {...rest}
      title={data.videoId?.text}
      src={`https://www.youtube.com/embed/${data.videoId?.text}?autoplay=0&loop=0&mute=0&cc_load_policy=0&cc_lang_pref=nl&modest_branding=1&rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ) : null
}
