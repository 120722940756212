import React, { useRef, useState, useMemo, useEffect } from "react"
import cx from "classnames"
import mergeRefs from "react-merge-refs"
import { PopoutContext, PopoutArrow, PopoutContent } from "../Popout"


const NavigationPopoutArrow = () => {
  return <PopoutArrow className="text-white" />
}


export const NavigationPopout = React.forwardRef(
  (
    { to, className, style: styleProp, contentRef, show, children, ...rest },
    forwardedRef
  ) => {
    const ref = useRef()

    const [{ position, hidden }, setState] = useState({
      position: undefined,
      hidden: true,
    });
    
    const popoutContextValue = useMemo(() => {
      return {
        ref,
        placement: "bottom",
        to,
      }
    }, [to])

    useEffect(() => {
      if (to && ref.current) {
        setState(state => ({
          ...state,
          hidden: false,
        }));
        setTimeout(() => {
          const toBBox = to.getBoundingClientRect()
          const popoutBBox = ref.current.getBoundingClientRect()
          setState(state => ({
            ...state,
            position: {
              x: toBBox.left + toBBox.width / 2 - popoutBBox.width / 2,
              y: toBBox.bottom
            }
          }));
        }, 0)
      }
    }, [to]);

    const shouldShow = position && show;

    useEffect(() => {
      let timeout;
      if(shouldShow) {
        setState(state => ({
          ...state,
          hidden: false
        }));
      }
      else {
        timeout = setTimeout(() => {
          setState(state => ({
            ...state,
            hidden: !shouldShow
          }))
        }, 200);
      }
      return () => {
        clearTimeout(timeout);
      }
    }, [
      shouldShow
    ]);

    const classNames = cx("fixed", "top-0", "left-0", className)

    const style = position ? {
      visibility: "invisible",
      transform: `translate3d(${position.x}px, ${position.y}px, 0)`
    } : {
      visibility: "visible",
    };

    return (
      <PopoutContext.Provider value={popoutContextValue}>
        <div
          ref={mergeRefs([ref, forwardedRef])}
          className={cx(
            "flex",
            shouldShow ? "opacity-1 z-10" : "opacity-0",
            hidden && "hidden",
            "transition-opacity",
            "duration-200",
            "ease-in-out",
            "items-center",
            "flex-col-reverse",
            classNames
          )}
          style={{ ...style, ...styleProp }}
          {...rest}
        >
          <PopoutContent
            ref={contentRef}
            className="bg-white shadow-m rounded-xl py-m"
          >
            {children}
          </PopoutContent>
          <NavigationPopoutArrow />
        </div>
      </PopoutContext.Provider>
    )
  }
)
