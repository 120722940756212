import React from "react"
import cx from "classnames";

export const Hero = React.forwardRef(({ children, className }, ref) => {
  return (
    <div ref={ref} className={cx("flex" ,"flex-col", "justify-start", className)}>
      {children}
    </div>
  )
})
