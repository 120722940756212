import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react"
import Mustache from "mustache";
import { MdPerson } from "react-icons/md"
import styled from "styled-components"
import cx from "classnames"
import { PageContext } from "../contexts/PageContext"
import Check from "../images/Check.inline.svg"
import { StrapiButton } from "../strapi/StrapiButton"
import { StrapiRichTextRenderer } from "../strapi/StrapiRichTextRenderer"
import HeadlineRenderer from "./HeadlineRenderer"
import { Popout, PopoutContent, PopoutArrow } from "../components/Popout"
import { Slider, Tick, TickLabel } from "../components/Slider"
import { usePricingData } from "../hooks/usePricingData"
import { marginClasses } from "../util/cssClasses"

const PricingPlan = styled.div.attrs({
  className: "flex items-center",
})``

const PricingPlanContent = styled.article.attrs({
  className: "w-full p-l rounded-xl shadow-xl",
})``

const PricingPlanTitle = styled.h3.attrs({
  className: "font-bold text-xl text-center mb-m",
})``

const PricingPlanPrice = styled.div.attrs({
  className: "text-4xl text-center",
})``

const TickRenderer = ({ getTickProps, value }) => (
  <Tick {...getTickProps()}>
    <TickLabel className="text-white">{value}</TickLabel>
  </Tick>
)

const SliderWithPopout = ({
  boundaryElement,
  pricingData,
  popoutTemplate,
  initialValue,
  onChange,
}) => {
  const [handle, setHandle] = useState()
  const popperInstance = useRef()
  const [showPopout, setShowPopout] = useState(false)

  const [value, setValue] = useState(initialValue + 500)
  const plan = pricingData[Math.floor(value / 1000)]

  useEffect(() => {
    const cHandle = handle
    function moveListener(e) {
      e.preventDefault()
    }

    if (cHandle) {
      cHandle.addEventListener("touchmove", moveListener)
    }

    return () => {
      if (cHandle) cHandle.removeEventListener("touchmove", moveListener)
    }
  }, [handle])

  const handleChange = useCallback(
    value => {
      setValue(value)
      onChange(Math.floor(value / 1000))
      popperInstance.current.update()
    },
    [onChange]
  )

  useEffect(() => {
    setTimeout(() => {
      setShowPopout(true)
    }, 1000)
  }, [])

  const renderTick = useMemo(
    () => ({ value, ...rest }) => {
      const valuePlan = pricingData[Math.floor(value / 1000) - 1]
      const newValue = valuePlan ? valuePlan[1] : pricingData[0][1]
      return <TickRenderer {...rest} value={newValue} />
    },
    [pricingData]
  )

  const [, participants, price] = plan
  const pricePerParticipant = price / participants

  const amountSections = pricingData?.length || 0
  const minTick = pricingData ? pricingData[0][1] : 0

  const popoutContent = Mustache.render(popoutTemplate, {
    participants: () =>
      `<div class="text-yellow-300 inline">${participants}</div>`,
    price: () =>
      `<div class="text-yellow-300 inline">€${pricePerParticipant.toFixed(
        2
      )}</div>`,
  })

  return (
    <>
      {showPopout && (
        <Popout
          to={handle}
          placement="top"
          className={cx("transition-opacity duration-200 ease-in-out")}
          instance={popperInstance}
          boundaryElement={boundaryElement}
        >
          <PopoutArrow className="text-black" />
          <PopoutContent className="rounded-xl shadow-lg p-s bg-black text-white flex items-center">
            <MdPerson className="inline" />
            <div dangerouslySetInnerHTML={{ __html: popoutContent }}></div>
          </PopoutContent>
        </Popout>
      )}
      <Slider
        className="mt-xl mb-m"
        min={1}
        max={amountSections * 1000 - 1}
        value={value}
        onChange={handleChange}
        zones={amountSections}
        ticks={[minTick, amountSections * 1000]}
        renderTick={renderTick}
        handleRef={setHandle}
      />
    </>
  )
}

const pricingPlanFeaturesRenderers = {
  list: ({ children }) => (
    <ul className="my-m flex flex-col space-y-s">{children}</ul>
  ),
  listItem: ({ children }) => (
    <li>
      <Check className="inline text-green-500 mr-m" />
      {children}
    </li>
  ),
}

export const PricingSectionRenderer = ({ data }) => {
  const pricingData = usePricingData()
  const boundaryRef = useRef()

  const { data: pageData } = useContext(PageContext)

  const { trialPlan, singlePlan, subscriptionPlan } = pageData.strapi || {}

  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(0)
  const [facilitators, participants, price] = pricingData[
    selectedSubscriptionPlan
  ] || [0, 0, 0]

  const templateData = useMemo(() => {
    return {
      participants: () =>
        `<div class="text-yellow-300 inline">${participants}</div>`,
      facilitators: () =>
        `<div class="text-yellow-300 inline">${facilitators}</div>`,
    }
  }, [participants, facilitators])

  return (
    <section
      className={cx(
        "flex flex-col items-center",
        marginClasses(data.margins, { marginBottom: "xl" })
      )}
    >
      <HeadlineRenderer level="display-1" data={data.headline} />
      <StrapiRichTextRenderer className="max-w-prose" data={data.text} />
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 gap-y-l mt-l">
        <PricingPlan>
          <PricingPlanContent className="bg-green-100">
            <header>
              <PricingPlanTitle>{trialPlan?.name?.text}</PricingPlanTitle>
            </header>
            <StrapiRichTextRenderer
              className="text-center"
              data={trialPlan?.description}
            />
            <PricingPlanPrice className="my-m font-bold">
              {trialPlan?.price?.text}
            </PricingPlanPrice>
            <StrapiRichTextRenderer
              renderers={pricingPlanFeaturesRenderers}
              data={trialPlan?.features}
            />
            <div className="text-center">
              <StrapiButton data={trialPlan?.button} />
            </div>
          </PricingPlanContent>
        </PricingPlan>
        <PricingPlan ref={boundaryRef}>
          <PricingPlanContent className="dark bg-blue-500 text-white">
            <header>
              <PricingPlanTitle>
                {subscriptionPlan?.name?.text}
              </PricingPlanTitle>
            </header>
            <StrapiRichTextRenderer
              className="text-center"
              data={subscriptionPlan?.description}
            />
            <SliderWithPopout
              boundaryElement={boundaryRef}
              pricingData={pricingData}
              initialValue={selectedSubscriptionPlan}
              popoutTemplate={subscriptionPlan?.sliderPopoutTemplate?.text}
              onChange={setSelectedSubscriptionPlan}
            />
            <div className="w-full text-center">
              <small>{subscriptionPlan?.pricePrefix?.text}</small>
            </div>
            <PricingPlanPrice className="text-yellow-300 font-semibold my-s">
              €{price.toFixed(0)}
            </PricingPlanPrice>
            <div className="w-full text-center">
              <p className="text-subtitle uppercase">
                {subscriptionPlan?.priceSuffix?.text}
              </p>
            </div>
            <StrapiRichTextRenderer
              allowDangerousHtml
              data={subscriptionPlan?.featuresTemplate}
              renderers={pricingPlanFeaturesRenderers}
              templateData={templateData}
            />
            <div className="text-center">
              <StrapiButton data={subscriptionPlan?.button} />
            </div>
          </PricingPlanContent>
        </PricingPlan>
        <PricingPlan>
          <PricingPlanContent className="bg-green-100">
            <header>
              <PricingPlanTitle className="font-bold">
                {singlePlan?.name?.text}
              </PricingPlanTitle>
            </header>
            <StrapiRichTextRenderer
              className="text-center"
              data={singlePlan.description}
            />
            <PricingPlanPrice className="font-semibold my-s">
              {singlePlan?.price?.text}
            </PricingPlanPrice>
            <div className="w-full text-center">
              <p className="text-subtitle uppercase">
                {singlePlan?.priceSuffix?.text}
              </p>
            </div>
            <StrapiRichTextRenderer
              data={singlePlan.features}
              renderers={pricingPlanFeaturesRenderers}
            />
            <div className="text-center">
              <StrapiButton data={singlePlan?.button} />
            </div>
          </PricingPlanContent>
        </PricingPlan>
      </div>
    </section>
  )
}
