import React from "react"
import PropTypes from "prop-types"

import CheckboxEmpty from "./checkbox_empty_24x24px.inline.svg"
import CheckboxFilled from "./checkbox_filled_24x24px.inline.svg"

import styled from "styled-components"

const StyledCheckboxEmpty = styled(CheckboxEmpty)`
  display: inline;
`
const StyledCheckboxFilled = styled(CheckboxFilled)`
  display: inline;
`

export const Checkbox = ({ checked, ...rest }) => {
  return checked ? (
    <StyledCheckboxFilled {...rest} />
  ) : (
    <StyledCheckboxEmpty {...rest} />
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
}

Checkbox.defaultProps = {
  checked: false,
}
