import { useContext } from "react";
import { useIntl } from "../util/useIntl";
import { PageContext } from "../contexts/PageContext";

export const useStrapiNavigationAction = id => {
  const { intl } = useIntl();
  const { data } = useContext(PageContext);
  

  const action = data.strapi?.navigationActions?.find(node => {
    return node.id === id
  });

  return action ? {
    ...action,
    props: {
      href: intl(action.location?.href).get(),
      children: intl(action.label).get(),
    }
  } : {}
}
