import React from "react";
import cx from "classnames";

export const WaterBackground = ({ color, className, style }) => {
  return (
    <div className={className} style={style}>
    <div className={cx("relative", "w-full", "h-full")}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        height={20}
        className="absolute w-full"
      >
        <pattern
          id="water"
          width=".25"
          height="1.1"
          patternContentUnits="objectBoundingBox"
        >
          <path
            fill={color}
            d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,1z"
          />
        </pattern>

        <rect
          className="water-fill"
          fill="url(#water)"
          x="-50%"
          y="0"
          width="200%"
          height="50"
          opacity="0.1"
        >
          <animate
            attributeType="xml"
            attributeName="x"
            from="-50%"
            to="0"
            repeatCount="indefinite"
            dur="5.4412s"
          />
        </rect>

        <rect
          className="water-fill"
          fill="url(#water)"
          x="-50%"
          y="0"
          width="200%"
          height="50"
          opacity="0.1"
        >
          <animate
            attributeType="xml"
            attributeName="x"
            from="-50%"
            to="0"
            repeatCount="indefinite"
            dur="2.95123s"
          />
        </rect>

        <rect
          className="water-fill"
          fill="url(#water)"
          x="-50%"
          y="0"
          width="200%"
          height="50"
          opacity="0.1"
        >
          <animate
            attributeType="xml"
            attributeName="x"
            from="-50%"
            to="0"
            repeatCount="indefinite"
            dur="2.516234s"
          />
        </rect>
      </svg>
      <div className="w-full absolute" style={{ background: color, opacity: 0.28, top: "20px", height: "calc(100% - 20px)" }} />
    </div>
    </div>
  )
}
