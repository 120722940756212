import React from "react"
import cx from "classnames"
import ReactMarkdown from "react-markdown"
import { StrapiButton } from "../strapi/StrapiButton"
import { Heading, HeadingTitle } from "../components/Heading"
import HeadlineRenderer from "./HeadlineRenderer"
import { marginClasses } from "../util/cssClasses";

const renderers = {
  heading: ({ level, children }) => {
    return (
      <Heading level={`h${level + 2}`}>
        <HeadingTitle children={children} />
      </Heading>
    )
  },
  link: ({ node, children }) => {
    return (
      <a className="text-blue-500 hover:underline" href={node.url}>
        {children}
      </a>
    )
  },
  paragraph: ({ node, children }) => {
    return <p className={"py-s"} children={children} />
  },
  blockquote: ({ children }) => {
    return (
      <blockquote
        className="my-s px-l py-s italic border-l-4"
        children={children}
      ></blockquote>
    )
  },
  list: ({ children }) => {
    return <ul className="list-disc list-outside px-m" children={children} />
  },
}

const colorClasses = {
  green: "bg-green-100",
  blue: "bg-blue-400 text-white",
}

export const PricingDetailSectionRenderer = ({ data }) => {
  return (
    <section
      id={data.anchor.text}
      className={cx("lg:grid lg:grid-cols-2 gap-xl", marginClasses(data.margins, { marginBottom: "xl" }))}
    >
      <div>
        <HeadlineRenderer
          defaultSize="h2"
          className="text-left"
          data={data.headline}
        />
        <div className="max-w-prose">
          <ReactMarkdown renderers={renderers}>
            {data.description.text}
          </ReactMarkdown>
        </div>
      </div>
      <div
        className={cx(
          "rounded-lg",
          "px-l",
          "pt-m",
          "mt-m",
          "pb-l",
          "lg:mt-0",
          colorClasses[data.summaryColor]
        )}
      >
        <div className="mb-m">
          <img
            src={data.summaryArt.localFile.publicURL}
            width={200}
            height={200}
            className="hidden lg:inline-block float-right"
            alt=""
          />
          <ReactMarkdown renderers={renderers}>
            {data.summary.text}
          </ReactMarkdown>
        </div>
        <StrapiButton data={data.summaryButton} />
      </div>
    </section>
  )
}
