import { createGlobalStyle } from "styled-components";
import { theme } from "twin.macro"

export const GlobalStyles = createGlobalStyle`
  html {
    --navbar-height: 112px;
    @media (min-width: ${theme("screens.md")}) { 
      --navbar-height: 60px;
    };
  }
  
  html, body {
    height: 100%;
  }
`