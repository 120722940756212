import React from "react"
import cx from "classnames"
import { marginClasses, maxWClasses } from "../util/cssClasses"
import { useIntl } from "../util/useIntl"
import { ArrowLink } from "../components/ArrowLink"
import HeadlineRenderer from "./HeadlineRenderer"
import { StrapiImageRenderer } from "../strapi/StrapiImageRenderer"
import { StrapiRichTextRenderer } from "../strapi/StrapiRichTextRenderer"

const colsClasses = [
  "grid-cols-1",
  "grid-cols-1 lg:grid-cols-2",
  "grid-cols-1 lg:grid-cols-3",
  "grid-cols-1 lg:grid-cols-4",
]
const getColsClasses = (columns = 1) => {
  const cols = Math.max(1, Math.min(columns, 4))
  return colsClasses[cols - 1]
}

export const ColumnsSectionRenderer = ({ data }) => {
  const { intl } = useIntl()

  const { columns = [] } = data
  return (
    <section
      className={cx(
        "flex flex-col mb-xl w-full",
        marginClasses(data.margins, { marginBottom: "xxl" }),
        maxWClasses(data.maxWidthColumns)
      )}
    >
      <HeadlineRenderer
        defaultSize="h2"
        className="text-center mb-l"
        data={data?.headline}
      />
      <div
        className={cx(
          "grid gap-y-m lg:gap-x-l",
          getColsClasses(data.amountColumns)
        )}
      >
        {columns.map(column => (
          <article key={column.id} className="flex flex-col">
            {column.image && (
              <a
                href={intl(column?.link?.href).get()}
                title={intl(column?.linkText).orPlaceholder()}
                className="w-full mb-m"
              >
                <StrapiImageRenderer data={column.image} />
              </a>
            )}
            <div className="px-m">
              <StrapiRichTextRenderer data={column?.text} />
              {column?.link && column?.linkText && (
                <ArrowLink href={intl(column.link.href).get()}>
                  {intl(column.linkText).orPlaceholder()}
                </ArrowLink>
              )}
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}
