import React from "react"
import { Link } from "../Link"
import PropTypes from "prop-types"
import cx from "classnames"
import { Box } from "../Box"
// import StyledButton from "./StyledButton";

const sizeClasses = {
  s: "px-s py-xxs",
  m: "px-m py-xs",
  l: "px-m py-s",
}

const typeClasses = {
  primary: `bg-blue-500 text-white hover:bg-blue-600 hover:shadow`,
  secondary: `bg-green-500 hover:bg-green-600 hover:shadow text-gray-700`,
  "primary-fill": `bg-blue-500 text-white hover:bg-blue-600 hover:shadow text-white-700`,
  "secondary-fill": `bg-green-500 hover:bg-green-600 hover:shadow text-gray-700`,
  "primary-outline": `border-blue-500 hover:shadow hover:border-blue-600`,
  "secondary-outline": `border-green-500 hover:shadow hover:border-green-600`,
}

export const Button = React.forwardRef(
  ({ className, type, size, inset, ...props }, ref) => {
    return (
      <Box
        {...props}
        $inset={inset}
        ref={ref}
        $as={props.href ? Link : props.as}
        $forwardedAs={props.href ? "a" : props.as}
        activeClassName="underline"
        tabIndex={0}
        className={cx(
          "border-transparent",
          sizeClasses[size],
          typeClasses[type],
          "rounded-xl",
          "tracking-wider",
          "transition",
          "ease-in-out",
          "duration-200",
          "border-2",
          "whitespace-nowrap",
          className
        )}
      />
    )
  }
)

Button.defaultProps = {
  as: "button",
  size: "m",
  type: null,
  href: undefined,
}

Button.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["s", "m", "l"]),
  href: PropTypes.string,
  type: PropTypes.oneOf([
    "naked",
    "primary",
    "secondary",
    "primary-outline",
    "secondary-outline",
    "primary-fill",
    "secondary-fill",
  ]),
}
