import styled from "styled-components";
import { Link } from "../components/Link";

export const ArrowLink = styled(Link).attrs(props => ({
  className: "text-subtitle uppercase underline mt-auto text-blue-500 dark:text-white"
}))`
  grid-area: link;
  :before {
    content: "→"
  }
`;