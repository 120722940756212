import React from "react"
import ReactMarkdown from "react-markdown"
import cx from "classnames"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { theme } from "twin.macro"
import { StrapiRichTextRenderer } from "../strapi/StrapiRichTextRenderer"
import { WaterBackground } from "../components/WaterBackground"
import { useIntl } from "../util/useIntl"

import upperArt from "../images/hero-abovewater.svg"
import lowerArt from "../images/hero-underwater.svg"

import { Hero, HeroAction } from "../components/Hero"

const HeroContainer = styled.div.attrs({
  className: "h-screen relative w-full",
})`
  margin-top: calc(var(--navbar-height) * -1);
  @media (min-width: ${theme("screens.md")}) {
    margin-top: calc(var(--navbar-height) * -2);
  }
`

const HeroBackground = styled.div.attrs({
  className: "fixed top-0 left-0 w-full h-screen",
})`
  z-index: -1;
`

export const HeroActionRenderer = ({ data }) => {
  const { intl } = useIntl()
  return (
    <>
      <HeroAction type={data.type} href={intl(data.link?.href).get()}>
        <ReactMarkdown>{data.content.text}</ReactMarkdown>
      </HeroAction>
    </>
  )
}

const heroMarkdownRenderers = {
  paragraph: ({ children }) => {
    return <h1 className="leading-relaxed py-s" children={children} />
  },
}

export function HeroSectionRenderer({ data }) {
  const { ref: markerRef, inView: markerInView } = useInView({
    initialInView: true,
  })

  const heroActions = data.actions.map(data => {
    return <HeroActionRenderer data={data} key={data.id} />
  })

  return (
    <HeroContainer>
      <HeroBackground>
        <WaterBackground
          color="#67b2d1"
          className={cx(
            "absolute",
            "w-full",
            "left-0",
            "bottom-0",
            "z-0",
            "transform",
            "transition-transform",
            !markerInView && "translate-y-full",
            "duration-500",
            "ease-in-out",
            "h-1/2"
          )}
        />
      </HeroBackground>
      <Hero className="relative z-10 h-full">
        <div className="h-1/2 text-3xl sm:text-5xl whitespace-pre-wrap flex flex-row items-end justify-between">
          <StrapiRichTextRenderer
            renderers={heroMarkdownRenderers}
            data={data.heading}
          />
          <img className="w-1/2 -mb-l hidden lg:block" src={upperArt} alt="" />
        </div>
        <div
          className="flex flex-row items-start justify-between"
          ref={markerRef}
        >
          <div className="my-m">
            <StrapiRichTextRenderer data={data.description} />
            <div className="my-m flex flex-col items-start space-y-s md:space-y-0 md:flex-row md:space-x-m">
              {heroActions}
            </div>
          </div>
          <img className="w-1/2 hidden lg:block" src={lowerArt} alt="" />
        </div>
      </Hero>
    </HeroContainer>
  )
}
