import React from "react"
import cx from "classnames"
import { StrapiRichTextRenderer } from "../strapi/StrapiRichTextRenderer"
import { alignClasses, marginClasses } from "../util/cssClasses"

export const TextSectionRenderer = ({ data, className }) => {
  return (
    <section
      className={cx(
        "flex flex-col w-full ",
        marginClasses(data.margins, { marginBottom: "xl" }),
        className
      )}
    >
      <StrapiRichTextRenderer
        data={data.content}
        className={cx("max-w-screen-md", "w-full", alignClasses(data.content))}
      />
    </section>
  )
}
