import cx from "classnames"

export const selfAlignClasses = ({ align }, defaultAlign = "left") => {
  const _align = align || defaultAlign
  switch (_align) {
    case "left":
      return "self-start"
    case "center":
      return "self-center"
    case "right":
      return "self-end"
    default:
      return null
  }
}

export const textAlignClasses = ({ alignText }, defaultAlign = "left") => {
  const _align = alignText || defaultAlign
  switch (_align) {
    case "left":
      return "text-left"
    case "center":
      return "text-center"
    case "right":
      return "text-right"
    default:
      return null
  }
}

export const alignClasses = (data = {}) => {
  return cx(selfAlignClasses(data), textAlignClasses(data))
}

const marginBottomClasses = {
  xxs: "mb-xxs",
  xs: "mb-xs",
  s: "mb-s",
  m: "mb-m",
  l: "mb-l",
  xl: "mb-xl",
  xxl: "mb-xxl",
}
const marginTopClasses = {
  xxs: "mt-xxs",
  xs: "mt-xs",
  s: "mt-s",
  m: "mt-m",
  l: "mt-l",
  xl: "mt-xl",
  xxl: "mt-xxl",
}
export const marginClasses = (options, defaults) => {
  const _options = options || {}
  const _defaults = defaults || {}
  return cx(
    marginTopClasses[_options.marginTop || _defaults.marginTop],
    marginBottomClasses[_options.marginBottom || _defaults.marginBottom]
  )
}

export const maxWClasses = (maxW, defaultW) => {
  const classes = {
    prose: "max-w-prose",
    sm: "max-w-screen-sm",
    md: "max-w-screen-md",
    lg: "max-w-screen-lg",
    xl: "max-w-screen-xl",
  }

  return classes[maxW || defaultW]
}
