/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext } from "react"
import { PageContext } from "../contexts/PageContext"
import { useIntl } from "../util/useIntl"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export function Seo({ meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const { intl } = useIntl()

  const { language: lang, data, languageLinks } = useContext(PageContext)
  const {
    strapi: {
      page: { title, description },
    },
  } = data

  const metaDescription = intl(description).or(site.siteMetadata.description)
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={intl(title).get()}
      titleTemplate={defaultTitle ? `${defaultTitle} | %s` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: intl(title).or(defaultTitle),
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: intl(title).or(defaultTitle),
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {Object.keys(languageLinks)
        .filter(l => l !== lang)
        .map(l => (
          <link
            key={l}
            rel="alternate"
            href={languageLinks[l]}
            hrefLang={l === "default" ? "x-default" : l}
          />
        ))}
    </Helmet>
  )
}

Seo.defaultProps = {
  meta: [],
}

Seo.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
}
