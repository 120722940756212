export const NAVIGATION_ACTION_REFERENCE =
  "STRAPI_ComponentNavigationNavigationActionReference"
export const NAVIGATION_LANGUAGE_SELECT_REFERENCE =
  "STRAPI_ComponentLanguageLangSelectReference"
export const NAVIGATION_MENU_REFERENCE =
  "STRAPI_ComponentNavigationMenuReference"

export const PAGE_CONTENT_IMAGE = "STRAPI_ComponentPageContentImage"
export const PAGE_CONTENT_YOUTUBE_VIDEO =
  "STRAPI_ComponentPageContentYoutubeVideo"
export const PAGE_CONTENT_RICH_TEXT =
  "STRAPI_ComponentInternationalizationLanguageRichText"
export const PAGE_CONTENT_TEXT =
  "STRAPI_ComponentInternationalizationLanguageText"
export const PAGE_CONTENT_IMAGE_CAROUSEL =
  "STRAPI_ComponentPageContentImageCarousel"
