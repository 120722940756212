import React from "react"
import { useIntl } from "../util/useIntl"
import { Button } from "../components/Button"

export const StrapiButton = ({ data = {}, ...rest }) => {
  const { intl } = useIntl()

  const type = data.style && data.color ? `${data.color}-${data.style}` : null

  return (
    <Button {...rest} type={type} href={intl(data.location?.href).get()}>
      {intl(data.text).orPlaceholder()}
    </Button>
  )
}
