import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { HeadingContext } from "./HeadingContext"
import styled from "styled-components"

const levelClasses = ({ level }) => {
  switch (level) {
    case "display-1":
      return "mb-m lg:mb-xl"
    case "display-2":
      return "mb-l"
    case "display-3":
      return "mb-m"
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      return "pb-m"
    default:
      return null
  }
}

const Header = styled.header.attrs(props => ({
  className: cx(levelClasses(props)),
}))``

export const Heading = props => {
  return (
    <HeadingContext.Provider value={{ level: props.level }}>
      <Header {...props} />
    </HeadingContext.Provider>
  )
}

Heading.propTypes = {
  level: PropTypes.oneOf([
    "display-1",
    "display-2",
    "display-3",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ]),
}

Heading.defaultProps = {
  level: "display-3",
}
