import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { HeadlineRenderer } from "./HeadlineRenderer"
import { theme } from "twin.macro"
import { ImageCarousel } from "../components/ImageCarousel"
import { StrapiRichTextRenderer } from "../strapi/StrapiRichTextRenderer"
import { StrapiImageRenderer } from "../strapi/StrapiImageRenderer"
import { useIntl } from "../util/useIntl"

import styled from "styled-components"

const StyledImageCarousel = styled(ImageCarousel)`
  --swiper-navigation-color: ${theme`colors.gray.500`};
  --swiper-pagination-color: ${theme`colors.gray.500`};

  .swiper-pagination-bullets {
    padding: 0.5em;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .swiper-pagination-bullet {
    height: 1em;
    width: 1em;
  }
`

export const ShowcaseSectionRenderer = ({ data }) => {
  const { intl } = useIntl()
  const { images, badges: badgesData, description } = data || {}

  const carouselImages = (images || []).map(({ image: { resolved } }) => {
    return {
      src: resolved.localFile.childImageSharp.fluid.src,
      alt: resolved.localFile.alternativeText,
    }
  })

  const badges = (badgesData || []).map(({ image, location, id }) => {
    return (
      <Link to={intl(location.href).get()}>
        <StrapiImageRenderer
          data={image}
          alt={image.alternativeText}
          key={id}
          className="h-10 inline rounded-full"
        />
      </Link>
    )
  })

  return (
    <section className="flex flex-col items-center mb-l w-full">
      <div className="-mx-l flex flex-col-reverse lg:flex-row w-full max-w-4xl rounded-3xl bg-blue-200 overflow-hidden shadow-lg">
        <div className="w-full lg:w-1/3 p-l bg-blue-500 text-white">
          {description && <StrapiRichTextRenderer data={description} />}
        </div>
        <div className="w-full lg:w-2/3 relative p-l">
          <div className="aspect-w-16 aspect-h-9 shadow">
            <StyledImageCarousel images={carouselImages} className="w-full" />
          </div>
          <div className="flex flex-row space-x-s justify-end mt-m">
            {badges}
          </div>
        </div>
      </div>
    </section>
  )
}
