import React from "react";
import cx from "classnames";
import { textAlignClasses } from "../util/cssClasses";

export const StrapiTextRenderer = ({
  data = {},
  as: AsComponent = "p",
  className,
  ...rest
}) => (
  <AsComponent {...rest} className={cx(textAlignClasses(data), className)}>
    {data.text}
  </AsComponent>
)
