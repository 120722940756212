import React, { useState, useCallback } from "react";
import { CollapsibleContext } from "./CollapsibleContext";
      

export const Collapsible = ({ label, open, onChange, children, ...rest }) => {
  const [collapsedState, setCollapsedState] = useState(!open);

  const collapsed = open !== undefined ? !open : collapsedState;

  const setCollapsed = useCallback((open) => {
    onChange ? onChange(open) : setCollapsedState(open);
  }, [onChange]);

  return (
    <div {...rest}>
      <CollapsibleContext.Provider value={{ collapsed, setCollapsed }}>
        {children}
      </CollapsibleContext.Provider>
    </div>
  );
};