import React from "react"
import Img from "gatsby-image"
import cx from "classnames"
import { selfAlignClasses } from "../util/cssClasses"

export const StrapiImageRenderer = ({ data = {}, className, ...rest }) => {
  if (
    data.localFile?.childImageSharp?.fluid ||
    data.localFile?.childImageSharp?.fixed
  ) {
    const imageProp = {
      ...(data.localFile?.childImageSharp?.fluid
        ? { fluid: data.localFile?.childImageSharp?.fluid }
        : { fixed: data.localFile?.childImageSharp?.fixed }),
    }

    return (
      <Img
        {...rest}
        alt={data.localFile?.alternativeText}
        className={cx(className, selfAlignClasses(data))}
        {...imageProp}
      />
    )
  } else if (data.localFile?.publicURL) {
    return (
      <img
        {...rest}
        alt={data.localFile?.alternativeText}
        className={cx(className, selfAlignClasses(data))}
        src={data.localFile.publicURL}
      />
    )
  }
  return null
}
