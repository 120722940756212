import { useCallback, useContext } from "react"
import { PageContext } from "../contexts/PageContext";

export const useStrapiNavigationMenu = id => {
  const { data } = useContext(PageContext);

  const findMenu = useCallback(
    id => {
      return data?.strapi?.navigationMenus?.find(menu => {
        return menu?.id === id
      })
    },
    [data]
  )

  const menu = findMenu(id);

  return menu
}
