import React, { useContext } from "react";
import { HeadingContext } from "./HeadingContext";
import styled from "styled-components";
import cx from "classnames";

const levelClasses = ({ level }) => {
  switch(level) {
    case "display-1":
      return "text-display-xs md:text-display-l font-light";
    case "display-2": 
      return "text-display-xs md:text-display-m font-light";
    case "display-3":
      return "text-display-xxs md:test-display-s font-light";
    case "h1":
      return "text-heading-m md:text-heading-xl font-semibold";
    case "h2": 
      return "text-heading-s md:text-heading-l font-semibold";
    case "h3": 
      return "text-heading-xs md:text-heading-m font-semibold";
    case "h4":
      return "text-heading-xxs md:text-heading-s font-semibold";
    case "h5":
      return "text-heading-xxs md:text-heading-xs font-semibold";
    case "h6":
      return "text-heading-xxs md:text-heading-xxs font-semibold";
    default: 
      return null;
  }
}

const defaultAs = ({ level }) => {
  switch(level) {
    case "display-1":
    case "display-2":
    case "display-3":
      return "h1";
    default: return level;
  }
}

const Title = styled.p.attrs(( props, as ) => ({
  as: as || defaultAs(props),
  className: cx(levelClasses(props)),
}))``;

export const HeadingTitle = React.forwardRef(({ level: levelProp, ...props }, ref) => {
  const { level: levelContextValue } = useContext(HeadingContext);

  const level = levelProp || levelContextValue;

  return <Title level={level} ref={ref} {...props} />
});
