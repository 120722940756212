import React, { useMemo } from "react"
import Mustache from "mustache"
import ReactMarkdown from "react-markdown"
import { listItem as defaultListItem } from "react-markdown/lib/renderers"
import htmlParser from "react-markdown/plugins/html-parser"
import HtmlToReact from "html-to-react"
import gfm from "remark-gfm"
import cx from "classnames"
import styled from "styled-components"
import { theme } from "twin.macro"
import { Link } from "../components/Link"
import { Checkbox } from "../components/Checkbox"
import { Heading, HeadingTitle } from "../components/Heading"

var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)

const headingRegex = /^h([0-9])$/i

const Thead = styled.thead`
  td {
    background-color: ${theme`colors.blue.200`};
  }
`

const parseHtml = htmlParser({
  isValidNode: node => {
    return node.type !== "script"
  },
  processingInstructions: [
    {
      shouldProcessNode: function (node) {
        return node.name?.match(headingRegex)
      },
      processNode: function (node, children, index) {
        const matches = node.name.match(headingRegex)
        const level = matches[1]
        return (
          <Heading level={`h${level}`}>
            <HeadingTitle>{node.children[0].data}</HeadingTitle>
          </Heading>
        )
      },
    },
    {
      shouldProcessNode: function (node) {
        return node.name === "ul"
      },
      processNode: function (node, children, index) {
        return (
          <ul className={`list-disc list-outside px-m py-m`}>{children}</ul>
        )
      },
    },
    {
      shouldProcessNode: function (node) {
        return node.name === "a"
      },
      processNode: function (node, children, index) {
        return (
          <a
            {...node.attribs}
            className={cx("hover:underline", "text-blue-500")}
          >
            {children}
          </a>
        )
      },
    },
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ],
})

const renderers = {
  heading: ({ level, children }) => {
    return (
      <Heading level={`h${level}`}>
        <HeadingTitle children={children} />
      </Heading>
    )
  },
  paragraph: ({ children }) => {
    return <p className={"pb-m"} children={children} />
  },
  blockquote: ({ children }) => {
    return (
      <blockquote
        className="my-s px-l py-s italic border-l-4"
        children={children}
      ></blockquote>
    )
  },
  link: ({ children, node, href, title, ...args }) => {
    return (
      <Link
        href={href}
        title={title}
        className={cx("hover:underline", "text-blue-500")}
      >
        {children}
      </Link>
    )
  },
  list: ({ children }) => {
    return <ul className="list-disc list-outside px-m" children={children} />
  },
  listItem: ({ checked, children, ...props }) => {
    if (checked !== null && checked !== undefined) {
      return (
        <li className="py-xs list-none -mx-m">
          <Checkbox checked={!!checked} className="mx-s" />
          <span>{children}</span>
        </li>
      )
    }
    return defaultListItem(props)
  },
  table: ({ children }) => (
    <div className="w-full overflow-auto lg:overflow-hidden">
      <table className="w-full">{children}</table>
    </div>
  ),
  tableHead: ({ children }) => <Thead className="font-bold">{children}</Thead>,
  tableCell: ({ children }) => (
    <td className="first:border-l-0 border-l-2 border-blue-200 px-s lg:px-m">
      {children}
    </td>
  ),
  tableRow: ({ children }) => <tr className="even:bg-blue-100">{children}</tr>,
}

export const StrapiRichTextRenderer = ({
  data = {},
  className,
  children,
  renderers: renderersProp = {},
  templateData,
  ...rest
}) => {
  const finalRenderers = { ...renderers, ...renderersProp }

  const rendered = useMemo(() => {
    const text = data?.text || null
    if (templateData && text) {
      return Mustache.render(text, templateData)
    }
    return text
  }, [templateData, data.text])

  return rendered ? (
    <ReactMarkdown
      {...rest}
      renderers={finalRenderers}
      className={className}
      allowDangerousHtml
      plugins={[gfm]}
      astPlugins={[parseHtml]}
    >
      {rendered}
    </ReactMarkdown>
  ) : null
}
