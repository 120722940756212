import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";

export const Link = React.forwardRef(({ href, ...rest }, ref) => {
  if(href?.startsWith("https://") || href?.startsWith("http://")) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a ref={ref} href={href} {...rest} />
  }
  return <GatsbyLink ref={ref} to={href} {...rest} />
})

Link.propTypes = {
  href: PropTypes.string.isRequired,
};