import React, { useRef, useEffect, useMemo, useState } from "react"
import { createPopper, detectOverflow } from "@popperjs/core"
import { PopoutContext } from "./PopoutContext"

import styled from "styled-components"

const StyledPopout = styled.div`
  position: relative;
  padding: 0.25rem;
`

export const Popout = ({
  to,
  children,
  instance,
  placement = "auto",
  boundaryElement,
  ...rest
}) => {
  const [usedPlacement, setUsedPlacement] = useState()

  const popoutRef = useRef()
  const arrowRef = useRef()
  const popperInstance = useRef()

  const modifier = useMemo(
    () => [
      {
        name: "placementUpdater",
        enabled: true,
        phase: "main",
        fn({ state }) {
          setUsedPlacement(state.placement)
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: boundaryElement.current,
        }
      }
    ],
    [boundaryElement]
  )

  // eslint-disable-next-line no-undef
  useEffect(() => {
    if (popperInstance.current) popperInstance.current.destroy()
    if (to) {
      const popper = createPopper(to, popoutRef.current, {
        placement,
        ...(arrowRef.current
          ? {
              modifiers: [
                ...modifier,
                {
                  name: "computeStyles",
                  options: {
                    adaptive: false, // true by default
                  },
                },
                {
                  name: "arrow",
                  options: {
                    element: arrowRef.current,
                  },
                },
              ],
            }
          : {}),
      })
      popperInstance.current = popper
      if (instance) {
        instance.current = popper
      }
      return popper?.destroy
    }
  }, [modifier, placement, to, instance])

  return (
    <StyledPopout
      {...rest}
      ref={popoutRef}
      placement={usedPlacement}
      className={"z-50"}
    >
      <PopoutContext.Provider value={{ arrowRef, placement: usedPlacement }}>
        {children}
      </PopoutContext.Provider>
    </StyledPopout>
  )
}
