import React from "react"
import cx from "classnames"
import { marginClasses } from "../util/cssClasses";
import { ImageCarousel } from "../components/ImageCarousel"

// This is required for purging css
const aspectRatioClasses = [
  ["aspect-w-1", "aspect-h-1"],
  ["aspect-w-2", "aspect-h-2"],
  ["aspect-w-3", "aspect-h-3"],
  ["aspect-w-4", "aspect-h-4"],
  ["aspect-w-5", "aspect-h-5"],
  ["aspect-w-6", "aspect-h-6"],
  ["aspect-w-7", "aspect-h-7"],
  ["aspect-w-8", "aspect-h-8"],
  ["aspect-w-9", "aspect-h-9"],
  ["aspect-w-10", "aspect-h-10"],
  ["aspect-w-11", "aspect-h-11"],
  ["aspect-w-12", "aspect-h-12"],
  ["aspect-w-13", "aspect-h-13"],
  ["aspect-w-14", "aspect-h-14"],
  ["aspect-w-15", "aspect-h-15"],
  ["aspect-w-16", "aspect-h-16"],
]

export const ImageCarouselSectionRenderer = ({ data }) => {
  const images = data.carousel?.images?.map(({ image: { resolved } }) => {
    return {
      src: resolved.localFile.publicURL,
      alt: resolved.localFile.alternativeText,
    }
  })

  const [aspectWidth, aspectHeight] = (
    data.carouselAspectRatio || "16:9"
  ).split(":")
  const width = data.carouselWidth
  const height =
    data.carouselHeight === "auto" ? undefined : data.carouselHeight
  const [aspectWClass = undefined] = aspectRatioClasses[aspectWidth - 1] || []
  const [, aspectHClass = undefined] =
    aspectRatioClasses[aspectHeight - 1] || []

  const aspectClasses = (data.carouselHeight === "auto" ||
    data.carouselWidth === "auto") && [aspectWClass, aspectHClass]
  const justifyClass =
    data.justify === "start" || data.justify === "end"
      ? `flex-${data.justify}`
      : data.justify

  return (
    <section className={cx("w-full", marginClasses(data.margins))}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: justifyClass }}
      >
        <div style={{ position: "relative", width, height }}>
          <div
            className={cx(aspectClasses)}
            style={!aspectClasses ? { width: "100%", height: "100%" } : {}}
          >
            <ImageCarousel
              images={images}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
