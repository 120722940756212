import cx from "classnames";
import PropTypes from "prop-types";

import styled from "styled-components";

const insetClasses = {
  "xxs": "p-xxs", 
  "xs": "p-xs",
  "s": "p-s",
  "m": "p-m",
  "l": "p-l",
  "xl": "p-xl",
  "xxl": "p-xxl",
  "squish-xs": "px-xs py-xxs",
  "squish-s": "px-s py-xs",
  "squish-m": "px-m py-s",
  "squish-l": "px-s py-m",
  "squish-xl": "px-xl py-l",
  "squish-xxl": "px-xxl py-xl",
  "stretch-xs": "px-xxs py-xs",
  "stretch-s": "px-xs py-s",
  "stretch-m": "px-s py-m",
  "stretch-l": "px-m py-l",
  "stretch-xl": "px-l py-xl",
  "stretch-xxl": "px-xl py-xxl" 
};

export const Box = styled.div.attrs(props => {
  const insetClass = insetClasses[props.inset];
  return {
    className: cx(insetClass)
  };
})``;

Box.propTypes = {
  /**
   * Padding
   */
  inset: PropTypes.oneOf(Object.keys(insetClasses)),
};