import React from "react";
import styled from "styled-components"
import { useBreakpoint } from "../../util/useBreakpoint"
import breakpoints from "../../breakpoints"

const Styled = styled.nav.attrs({
  className: 'flex flex-inline items-center'
})``;

export const NavigationGroup = (props) => {
  const breakpoint = useBreakpoint(breakpoints, "md");

  switch(breakpoint) {
    case "sm":
    case "md":
      return <Styled {...props} className="ml-m" />
    default:
      return <Styled {...props} className="ml-l" />
  }
}