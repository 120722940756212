import React from "react"
import cx from "classnames";
import ReactMarkdown from "react-markdown"
import Img from "gatsby-image"
import { ArrowLink } from "../components/ArrowLink"
import { useIntl } from "../util/useIntl"
import HeadlineRenderer from "./HeadlineRenderer"
import { marginClasses } from "../util/cssClasses";

export const ModulesSectionRenderer = ({ data }) => {
  const { intl } = useIntl()
  const modules =
    data.modules?.map(module => {
      return (
        <div
          className="rounded-xl bg-white p-m hover:shadow-xl text-body2 transform transition duration-500 ease-in-out hover:scale-110"
          key={module.id}
        >
          <div className="w-l h-l rounded-full overflow-hidden mb-m">
            <img src={module.image.localFile.publicURL} alt="" />
          </div>
          <p className="font-bold text-left mb-m">{module.title.text}</p>
          <p className="mb-s">{intl(module.description).orPlaceholder()}</p>
          <ArrowLink href={intl(module.location.href).get()}>
            {intl(data.linkText).orPlaceholder()}
          </ArrowLink>
        </div>
      )
    }) || []

  return (
    <section className={cx("flex flex-col items-center", marginClasses(data.margins, { marginBottom: "xl"}))}>
      <div className="flex w-full justify-between">
        <div className="md:w-1/2 px-m md:px-l md:-mx-l md:px-l my-l rounded-xl bg-white justify-self-start">
          <HeadlineRenderer
            defaultSize="h2"
            className="text-left"
            data={data.headline}
          />
          <ReactMarkdown className="max-w-prose">
            {intl(data.text).get()}
          </ReactMarkdown>
        </div>
        {data.illustration?.localFile?.childImageSharp?.fixed && (
          <div className="w-1/3 hidden md:block">
            <Img
              fixed={data.illustration.localFile.childImageSharp.fixed}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 w-full content-center gap-x-m md:gap-x-l gap-y-l md:-mx-l">
        {modules}
      </div>
    </section>
  )
}
