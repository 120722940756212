import React from "react";
import styled from "styled-components";
import quotesArt from "./quotes.svg";

const Wrapper = styled.div.attrs({
  className: "flex items-end"
})`
  grid-area: quotes;
`;

export const TestimonialQuotes = () => {
  return (
    <Wrapper>
      <img src={quotesArt} style={{ gridArea: "quotes" }} alt="quotes" />
    </Wrapper>
  );
}