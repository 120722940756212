// install Swiper modules
import React from "react";
import Loading from "./Loading";
import loadable from "@loadable/component";

import 'swiper/swiper-bundle.css';


const loadCarousel = async () => {
  return import('./LazyImageCarousel');
}

const LazyImageCarousel = loadable(loadCarousel);

const ImageCarousel = (props) => (
  <LazyImageCarousel {...props} fallback={<Loading />}/>
);

export default ImageCarousel;